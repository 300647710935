import { defineStore } from 'pinia';
import { toggleBodyScroll } from '~/helpers/utils';
import { useCartStore } from './cart';

export const useSidebarStore = defineStore('sidebar', {
  state: () => ({
    isSidebarOpen: false,
    showCartRecommendations: false,
  }),
  actions: {
    setIsSidebarOpen(payload) {
      this.isSidebarOpen = payload;
    },
    setShowCartRecommendations(payload) {
      this.showCartRecommendations = payload;
    },
    toggleCartRecommendations(payload) {
      this.setShowCartRecommendations(payload ?? !this.showCartRecommendations);
    },

    toggleSidebar(payload) {
      const nuxtApp = useNuxtApp();

      const shouldOpenSidebar = payload ?? !this.isSidebarOpen;

      if (shouldOpenSidebar) {
        useCartStore().getCart();
      }

      this.setIsSidebarOpen(shouldOpenSidebar);

      // Disable body scroll if not desktop and sidebar is open
      // Enable body scroll if desktop or sidebar is closed
      toggleBodyScroll(
        nuxtApp.$viewport.isGreaterThan('sm') || !shouldOpenSidebar,
        false
      );
    },
  },
});
